<template>
  <div class="search_listbox">
    <div class="search_leftshape"></div>
    <div class="search_rightshape"></div>
    <div class="row g-0">
      <div class="col-9 d-flex align-items-stretch">
        <div class="search_bigbox d-flex justify-content-start align-items-center">
          <div class="thumb flex-shrink-1">
            <div class="select-fav">
              <input type="checkbox" class="custom-control-input" :id="`fab-${itemIndex}`"/>
              <label class="fav" :for="`fab-${itemIndex}`"></label>
            </div>
            <!-- <img v-bind="mainProps" src="assets/img/theme1/shottalimg1.jpg" alt="shottalimg1"> -->
            <img v-bind="mainProps" :src="hotel.imgUrl ? hotel.imgUrl : 'null'" :alt="hotelName || hotel.name">
          </div>
          <div class="search_bigbox_textbox">
            <h3>
              <a :href="`/hotel-info/?${hotelInfoQuery}`" onclick="return false;"  @click="() => gotoHotelsPage(`/hotel-info/?${hotelInfoQuery}`)">
                {{ hotelName || hotel.name }}
              </a>
              <b-badge :variant="hotel.supplierCode !== 'ATLANTIS' ? 'success' : 'danger'">
                    <span v-if="hotel.supplierCode === 'ATLANTIS'">{{$t("search-result.domestic-search.request")}}</span>
                    <span v-else>{{$t("search-result.domestic-search.available")}}</span>
              </b-badge>
            </h3>
            <div>
              <p><i class="fas fa-moon bluefly"></i> {{ getDuration(hotel.checkIn, hotel.checkOut) }} {{ $t("home.nights") }} / {{ getDuration(hotel.checkIn, hotel.checkOut) + 1 }} {{ $t("home.days") }}</p>
            </div>
            <div class="">
              <span class="item-block"><i class="fas fa-calendar-week bluefly"></i> <strong>{{ $t("search-result.trip-duration") }}:</strong> {{ getDate(hotel.checkIn, 'DD/MM/YYYY') }} {{ getDay(hotel.checkIn) }} - {{ getDate(hotel.checkOut, 'DD/MM/YYYY') }} {{ getDay(hotel.checkOut) }}</span>
            </div>
            <div>
              <span class="item-block">
                <i class="fas fa-user-friends bluefly ml-1" style="margin-right: -3px;"></i>
                <strong>{{ $t("search-result.domestic-search.occupancy") }}:</strong>
                {{hotel.adults}} {{ $t("product-page.adults")}}, {{hotel.children}} {{ $t("product-page.children")}}
              </span>
            </div>
            <div>
              <span class="item-block">
                <i class="fa fa-utensils bluefly"></i>
                <strong>{{ $t("booking.board-basis") }}:</strong>
                {{ hotel.basisName ? hotel.basisName : $t("basis-name." + hotel.basis.toLowerCase().trim())}}
              </span>
            </div>
            <div>
              <span class="item-block" >
                <img class="bluefly ml-2" src="assets/img/icon8.png" alt="bluefly" />
                <strong>{{ $t("product-page.room-type") }}:</strong>
                {{ hotel.roomName }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 d-flex align-items-stretch search_smalltotal">
        <div class="search_smallshapebox"></div>
        <div class="search_smallbox align-items-center">
          <div class="d-block text-center">
            <div class="price" v-if="hotel.discountPercent">
              <p class=""><s>₪{{ hotel.total }}</s></p>
              <p class="large"><Strong>₪{{ discountedPrice }}</Strong></p>
            </div>
            <div v-else>
              <p class="m-0"><s>₪{{ (Number(hotel.total) * 1.1).toFixed(0) }}</s></p>
              <p class="large">₪{{ discountedPrice }}</p>
            </div>
            <p class="mt-2 price-label">{{ $t("search-result.domestic-search.final-price-message") }}</p>
            <button class="btn btn-selectRoom btn-order" @click="orderNow">
              {{ $t("search-result.order-now") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue';
import TemplateFilterItem from './TemplateFilterItem';

export default {
  name: 'FilterItemTheme1',
  mixins: [TemplateFilterItem],
  components: {
    BBadge,
    // BModal,
    // BFormInput,
    // BButton,
    // BFormSelect,
    // BFormSelectOption,
    // HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    // MoreHotelPriceItem: () => import('@/components/searchResult/atoms/MoreHotelPriceItem'),
  },
  directives: {
    // 'b-modal': VBModal,
  },
};
</script>
<style scoped>
  .search_listbox {
      border: 1px solid #B4B4B4;
      margin-top: 24px;
      min-height: 284px;
      position: relative;
      border-left: none;
      border-right: 0;
  }
  .search_listbox .search_leftshape{ position:absolute; left:0; top:0; height:100%; z-index:2; }
  .search_listbox .search_rightshape{ position:absolute; right:0; top:0; height:100%; z-index:2; }
  .search_listbox .search_leftshape:after {
      content: "";
      position: absolute;
      top: -2px;
      left: 0px;
      width: 40px;
      height: 53px;
      background-image: url(/assets/img/theme1/shape3.png);
      background-size: contain;
      background-repeat: no-repeat;
  }
  .search_listbox .search_leftshape:before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 40px;
      height: 55px;
      background-image: url(/assets/img/theme1/shape4.png);
      background-size: cover;
      background-repeat: no-repeat;
  }

  .search_listbox .search_rightshape:after {
      content: "";
      position: absolute;
      top: -2px;
      right: 0px;
      width: 40px;
      height: 55px;
      background-image: url(/assets/img/theme1/shape5.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
  }
  .search_listbox .search_rightshape:before {
      content: "";
      position: absolute;
      bottom: -2px;
      right: 0px;
      width: 40px;
      height: 53px;
      background-image: url(/assets/img/theme1/shape6.png);
      background-size: cover;
      background-repeat: no-repeat;
  }

  .search_listbox:after {
      content: "";
      position: absolute;
      right: 0;
      background-image: url(/assets/img/theme1/shape1.png);
      background-size: 50%;
      width: 19px;
      height: 100%;
      background-repeat: repeat-y;
      top: 0;
      background-position: 10px 0px;
  }
  .search_listbox:before {
      content: "";
      position: absolute;
      left: 0px;
      background-image: url(/assets/img/theme1/shape2.png);
      background-size: 50%;
      width: 19px;
      height: 100%;
      background-repeat: repeat-y;
      top: 0;
  }

  .search_listbox .row{ position:relative; z-index:9; }

  .search_listbox .search_bigbox {
      padding: 13px 44px 13px 15px;
  }
  .search_listbox .search_bigbox .thumb {
      min-width: 264px;
      max-width: 264px;
      height: 254px;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
  }
  .search_listbox .search_bigbox .thumb img{ width:100%; height: 100%; object-fit:cover; }
  .search_listbox .search_bigbox_textbox {padding-right: 24px;}
  .search_listbox .search_bigbox_textbox h3{ font-size:24px; font-weight:500; color:#000000; }
  .search_listbox .search_bigbox_textbox  .sterbox{ margin-bottom:15px; color:#FFC107; font-size:16px; }
  .search_listbox .search_bigbox_textbox  .sterbox i{ margin-right:5px; }
  .search_listbox .search_bigbox_textbox p {
      font-size: 16px;
      color: #000;
      font-weight: 400;
      margin-bottom: 0;
  }
  .search_listbox .search_bigbox_textbox .datedbox{ margin-top:15px; }
  .search_listbox .search_bigbox_textbox .datedbox h4{ font-size:17px; font-weight:600; }
  .search_listbox .search_bigbox_textbox .datedbox h4 small{ font-weight:400; font-size:16px; display:block; }
  .search_listbox .search_smallbox {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
  }
  .search_listbox .search_smallbox .price {
      font-size: 18px;
      color: #000000;
      line-height: 30px;
  }
  .search_listbox .search_smallbox .price strong{ font-size:36px; color:#1993C8; font-weight:700; display:block; }
  .search_listbox .search_smallbox  h4 {
      color: #707070;
      font-weight: 500;
      font-size: 18px;
      padding: 10px 0;
  }

  .btn-order {
      background-color: #23BEDC;
      padding: 10px 20px;
      margin-top: 0px;
      font-size: 15px;
      box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
      color: #fff;
      border-radius: 6px;
      display: inline-block;
  }
  .btn-order:hover{
      background-color: #000;
      color: #fff;
  }
  .search_smalltotal{ position:relative; }
  .search_smalltotal .search_smallshapebox{}
  .search_smalltotal .search_smallshapebox {
      position: absolute;
      right: -13px;
      background-image: url(/assets/img/theme1/shape9.png);
      background-size: 50%;
      width: 32px;
      height: 100%;
      background-repeat: repeat-y;
      top: 0;
      background-position: center;
  }
  .search_smalltotal .search_smallshapebox:after {
      content: "";
      position: absolute;
      top: -2px;
      left: -16px;
      width: 66px;
      height: 44px;
      background-image: url(/assets/img/theme1/shape7.png);
      background-size: contain;
      background-repeat: no-repeat;
  }
  .search_smalltotal .search_smallshapebox:before {
      content: "";
      position: absolute;
      bottom: -3px;
      left: -17px;
      width: 66px;
      height: 44px;
      background-image: url(/assets/img/theme1/shape8.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
  }
  .package-organize .search_listbox .select-fav{
    position: absolute;
    top: 20px;
    right: 65px;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bluefly {
    color: #2486c2;
    width: 20px;
  }
  @media(max-width: 1399px) {
    .search_listbox .search_bigbox .thumb {
        min-width: 220px;
        max-width: 220px;
        height: 224px;
    }
    .search_listbox .search_bigbox_textbox h3 {
        font-size: 22px;
    }
    .search_listbox .search_bigbox_textbox  .sterbox {
        margin-bottom: 12px;
        font-size: 14px;
    }
    .search_listbox .search_bigbox_textbox p {
        font-size: 14px;
    }
    .search_listbox .search_bigbox_textbox .datedbox h4 {
        font-size: 15px;
    }
    .search_listbox .search_bigbox_textbox .datedbox h4 small {

        font-size: 14px;

    }
    .search_listbox .search_bigbox_textbox .datedbox {
        margin-top: 12px;
    }
    .search_listbox {
        min-height: 258px;
    }
    .search_listbox .search_bigbox {
        padding: 16px 42px 16px 15px;
    }
    .search_smalltotal .search_smallshapebox:before {
        bottom: -1px;
        left: -16px;
        width: 59px;
        height: 40px;
    }
    .search_smalltotal .search_smallshapebox:after {

        top: -1px;
        left: -14px;
        width: 60px;
        height: 40px;

    }
    .search_smalltotal .search_smallshapebox {
        right: -13px;
        width: 27px;
        background-position: center 11px;
    }
    .search_listbox .search_rightshape:after {
      top: -1px;
    }
    .search_listbox .search_rightshape:before {
       bottom: -1px;
    }
    .search_listbox .search_leftshape:before {

        bottom: -1px;

    }
    .search_listbox .search_leftshape:after {

        bottom: -1px;

    }
  }
  @media (max-width:991.98px){
    .search_listbox .search_bigbox .thumb {
        min-width: 170px;
        max-width: 170px;
        height: 180px;
    }
    .search_listbox .search_bigbox_textbox h3 {
        font-size: 20px;
    }
    .search_listbox .search_bigbox_textbox .sterbox {
        margin-bottom: 8px;
        font-size: 10px;
    }
    .search_listbox .search_bigbox_textbox .datedbox {
        margin-top: 7px;
    }
    .search_listbox .search_bigbox_textbox .datedbox h4 {
        font-size: 13px;
    }
    .search_listbox .search_bigbox_textbox .datedbox h4 small {
        font-size: 12px;
    }
    .search_listbox .search_bigbox {
        padding: 12px 42px 12px 30px;
        width: 100%;
    }
    .search_listbox .search_smallbox .price strong {
        font-size: 24px;
    }
    .search_listbox .search_smallbox .price {
        font-size: 14px;
        line-height: 22px;
    }
    .search_listbox .search_smallbox h4 {
        font-size: 14px;
        padding: 7px 0;
    }
    .btn-order {

        padding: 7px 13px;
        font-size: 12px;

    }
    .search_listbox {
        min-height: 206px;
    }
  }
  @media (max-width:767.98px){
    .search_listbox::before {
        width: 10px;
    }
    .search_listbox::after {
        width: 11px;
        background-position: 5px 0px;
    }
    .search_listbox .search_rightshape::after {
        width: 25px;
        height: 35px;
    }
    .search_listbox .search_rightshape::before {
        width: 25px;
        height: 33px;
    }
    .search_listbox .search_leftshape::after {
        width: 24px;
        height: 35px;

    }
    .search_listbox .search_leftshape::before {
        width: 25px;
        height: 35px;

    }
    .search_listbox .search_bigbox {
        padding: 12px 24px 12px 26px;
    }
    .search_listbox .search_bigbox .thumb {
        min-width: 140px;
        max-width: 140px;
        height: 150px;
    }
    .search_listbox .search_bigbox_textbox h3 {
        font-size: 18px;
        margin-bottom: 5px;
    }
    .search_listbox .search_bigbox_textbox p {
        font-size: 12px;
        margin-bottom: 4px;
    }
    .search_smalltotal .search_smallshapebox {
        right: -5px;
        width: 14px;
    }
    .search_smalltotal .search_smallshapebox::before {
        left: -13px;
        width: 40px;
        height: 28px;
    }
    .search_smalltotal .search_smallshapebox::after {
        left: -12px;
        width: 40px;
        height: 28px;
    }
    .search_listbox {
        min-height: 176px;
    }
    .search_topbar .select-selected {
        height: 44px;
        display: flex;
        align-items: center;
        padding-top: 7px !important;
    }
    .select-selected::after {

        top: 12px;

    }
    .search_listbox {

        margin-top: 15px;
    }
    .button-nav-toggle {
        height: 38px;

    }
    .search_body .search_rightbox {
        padding-left: 0;
    }
    .search_smallbox .price p{
      margin-bottom: 5px;
    }
    .search_smallbox .price-label{
      padding: 5px;
      width: 40%;
    }
    .search_smallbox .btn-order{
      padding: 5px 12px;
    }
  }
  @media (max-width: 639.98px) {
    .search_listbox .col-3 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 auto;
    }
    .search_listbox .col-9 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 auto;
    }
    .search_listbox .search_smallbox {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: left;
        position: relative;
        padding: 2px 24px 4px;
    }
    .search_smalltotal .search_smallshapebox {
        display: none;
    }
    .search_listbox .search_smallbox .d-block {
        display: inline-flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .btn-order {
        padding: 8px 13px;
        font-size: 12px;
        margin-right: 13px;
    }
    .search_listbox .search_smallbox h4 {
        margin: 0;
        margin-right: 15px;
    }
    .search_listbox .search_bigbox_textbox div{
      line-height: 1;
    }
  }
  @media (max-width: 479.98px) {
    .search_listbox .search_bigbox_textbox {
        padding-right: 14px;
    }
    .search_listbox .search_bigbox_textbox h3 {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .search_bigbox.align-items-center {
        align-items: flex-start!important;
    }
    .search_listbox .search_bigbox .thumb {
        min-width: 100px;
        max-width: 100px;
        height: 146px;
    }
  }
</style>
